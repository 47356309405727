import styled, { css, device, themeGet } from "@styled";
import blueGradientBg from "@data/images/bg/bluegradientbg.png";

export const ImageTitleBoxWrapper = styled.section`
  background: #571883;
  background: linear-gradient(
    320deg,
    ${themeGet("colors.secondaryDark")},
    ${themeGet("colors.secondary")}
  );
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;

  .section-title {
    h2 {
      color: #f5491d;
    }
    p {
      color: #fff;
    }
  }
  a {
    border-radius: 4px;
    background: #fff;
    border-color: #e2e2e2;
    font-size: 17px;
  }

  ${(props) =>
    props.layout === 2 &&
    css`
      background: url(${blueGradientBg});
      background-position: center top;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 100px 0;

      .section-title {
        @media (min-width: 1200px) {
          margin-bottom: 40px;
        }
        @media (min-width: 1600px) {
          margin-bottom: 50px;
        }

        h6, h2 {
          color: #fa4f19;
        }
        p {
          color: #fff;
        }
      }
      a {
        border-radius: 4px;
        background: rgb(255 255 255 / 11%);
        border-color: rgb(255 255 255 / 41%);
        color: #fff;
        &:hover {
          color: #fff;
        }

        @media (max-width: 768px) {
          font-size: 16px;
        }

        @media (max-width: 576px) {
          font-size: 15px;
        }
      }
    `}
`;

export const ImageTitleBoxInner = styled.div``;

export const ImageTitleBoxBtns = styled.div`
  margin-left: -7px;
  margin-right: -7px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 5px 10px;
  margin-top: 50px;
  ${device.small} {
    grid-template-columns: repeat(1, 1fr);
  }
  ${device.medium} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${device.large} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const ImageTitleBoxWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  ${device.medium} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${device.xlarge} {
    grid-template-columns: repeat(4, 1fr);
  }
  & > a {
    display: flex;
  }
`;
export const ImageTitleBoxWrapInner = styled.div`
  padding: 40px 15px;
  border: 1px solid rgb(255 255 255 / 55%);
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255 255 255 / 11%);
  transition: ${themeGet("transition")};
  &:hover {
    box-shadow: 0 0 40px -16px rgb(255 255 255);
    transform: translateY(-3px);
  }
`;

export const ImageTitleBoxIconwrap = styled.div`
  img {
    max-width: 100px;
    width: 100%;
  }
`;
export const ImageTitleBoxTextWrap = styled.div`
  h6 {
    font-weight: normal;
    margin-top: 20px;
  }
  h4 {
    margin: 20px 0 0;
    font-size: 14px;
    font-weight: bold;
    ${device.xxlarge} {
      font-size: 16px;
    }
  }
  p {
    color: rgb(255 255 255 / 70%);
    font-size: 14px;
    ${device.xlarge} {
      font-size: 15px;
    }
    ${device.xxlarge} {
      font-size: 17px;
    }
  }
`;

export const ButtonWrap = styled.div`
  text-align: center;
  margin: 50px 0 0;

   a {
    background-color: #f94f19;
    border-color: #f94f19;
    border-radius: 15px;
   }
`;