import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Image from "@ui/image";
import Heading from "@ui/heading";
import { ButtonType } from "@utils/types";
import Button from "@ui/button";
import {
  ImageTitleBoxWrapper,
  ImageTitleBoxInner,
  ImageTitleBoxWrap,
  ImageTitleBoxWrapInner,
  ImageTitleBoxIconwrap,
  ImageTitleBoxTextWrap,
  ButtonWrap
} from "./style";

const ImageTitleBox = ({ data, layout, ...props }) => {
  return (
    <ImageTitleBoxWrapper layout={layout} {...props} id={data.section}>
      <ImageTitleBoxInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                subtitle={data.section_title?.subtitle}
                title={data.section_title?.title}
                description={data.section_title?.description}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <ImageTitleBoxWrap>
                {(data && data.items) &&
                  data.items.map((el, index) => (
                      <ImageTitleBoxWrapInner key={index}>
                        <ImageTitleBoxIconwrap>
                          <Image key={index}
                            src={el.images[0]?.src}
                            alt={el.images[0]?.alt}
                          />
                        </ImageTitleBoxIconwrap>
                        <ImageTitleBoxTextWrap> 
                          <Heading as="h4" className="text-white">
                            {el.title}
                          </Heading>
                        </ImageTitleBoxTextWrap>
                      </ImageTitleBoxWrapInner>
                  ))}
              </ImageTitleBoxWrap>
                      {data?.buttons?.map(({ id, content, ...rest }) => (
                        <ButtonWrap key={id}>
                          <Button m="7px" {...rest} key={id}>
                              {content}
                          </Button>
                        </ButtonWrap>
                    ))}
            </Col>
          </Row>
        </Container>
      </ImageTitleBoxInner>
    </ImageTitleBoxWrapper>
  );
};

ImageTitleBox.propTypes = {
  layout: PropTypes.oneOf([1, 2, 3, 4]),
  items: PropTypes.object,
  buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
};
ImageTitleBox.defaultProps = {
  layout: 1,
};

export default ImageTitleBox;
